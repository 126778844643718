// <div data-controller="components--dropdown">
//
// </div>
import { Controller } from "stimulus";
import api from "@/utils/api";

export default class extends Controller {
  static targets = [ "container", "dropdown", "toggleIcon", "toggleButton" ];

  toggle(e) {
    if (this.dropdownTarget.style.display === "none") {
      this.dropdownTarget.style.display = "block";
      this.toggleButtonTarget.classList.add("border-yellow-noon", "dark:border-yellow-noon")
      this.toggleIconTarget.classList.add("rotate-180", "transform-gpu");
    } else {
      this.dropdownTarget.style.display = "none";
      this.toggleButtonTarget.classList.remove("border-yellow-noon", "dark:border-yellow-noon")
      this.toggleIconTarget.classList.remove("rotate-180", "transform-gpu");
    }
  }

  connect() {
    document.addEventListener("click", (e) => {
      if (!this.containerTarget.contains(e.target)) {
        this.dropdownTarget.style.display = "none";
        this.toggleButtonTarget.classList.remove("border-yellow-noon", "dark:border-yellow-noon")
        this.toggleIconTarget.classList.remove("rotate-180", "transform-gpu");
      }
    })
  }
}