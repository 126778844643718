// <div data-controller="components--editor-js" id="editorContainer">
//
// </div>
import {Controller} from "stimulus";
import api from "@/utils/api";
import * as helpers from "@/utils/helpers";
import {DirectUpload} from "@rails/activestorage"
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import Quote from "@editorjs/quote"
import Delimiter from '@editorjs/delimiter';
// import List from '@editorjs/list';
import NestedList from '@editorjs/nested-list';
import ImageTool from '@editorjs/image';
import Table from '@editorjs/table';
import Marker from '@editorjs/marker';
import Underline from '@editorjs/underline';
import Undo from 'editorjs-undo';
import DragDrop from 'editorjs-drag-drop';
import RawTool from '@editorjs/raw';
import LinkTool from '@editorjs/link';
import Paragraph from 'editorjs-paragraph-with-alignment';
import CodeTool from "@editorjs/code";
import Embed from "@editorjs/embed";
import TextColor from "editorjs-color";
import AnyButton from "editorjs-button";
import AlignmentTuneTool from "editorjs-text-alignment-blocktune";
import { readAndCompressImage } from 'browser-image-resizer';

import korean from "@/i18n/editorjs-korean";


Delimiter.prototype.drawView = function () {
    let div = document.createElement('DIV');

    div.classList.add(this._CSS.wrapper, this._CSS.block);
    div.innerHTML = "<hr>";

    return div;
};

export default class extends Controller {
    static targets = [
        "editorForm", "editorData", "autoSaveSubmit", "autoSavedLabel", "saveAndPublish",
        "title", "subtitle", "tagList", "postCategorySlug"
    ];

    initialize() {
        this.editor = null;
        this.alreadySaving = false;
    }

    submitForm() {
        clearInterval(this.autoSaver);

        if (this.isExceptionalEnvironment()) {
            return;
        }

        this.enableLeave();

        this.editor.save().then((outputData) => {
            if (this.data.get("post-type") === "new") {
                localStorage.removeItem(this.data.get("post-ext-id"));
            }
            this.editorDataTarget.value = JSON.stringify(outputData);
            this.editorFormTarget.submit();
        }).catch((error) => {
            alert(error.response.data.error);
        });
    }

    publishNote(e) {
        this.enableLeave();

        if (this.isExceptionalEnvironment()) {
            return;
        }

        this.editor.save().then((data) => {
            this.editorDataTarget.value = JSON.stringify(data);
            this.saveAndPublishTarget.click();
        });
    }

    enableLeave = () => {
        this.data.set("protect-leave", "off");
    };

    leavingPage = (event) => {
        const areYouSure = "정말 페이지를 나가시나요? 저장을 하셨는지 꼭 확인해주세요 :)";

        if (this.data.get("protect-leave") === "on") {
            if (event.type === "turbolinks:before-visit") {
                if (!window.confirm(areYouSure)) {
                    event.preventDefault()
                }
            } else {
                event.returnValue = areYouSure;
                return event.returnValue;
            }
        }
    };

    // deepl extension : chrome-extension://cofdbpoegempjloogbagkncekinflcnj/build/content.css
    isExceptionalEnvironment = () => {
        const deeplInlineTranslator = document.querySelectorAll("deepl-inline-translate");
        if (deeplInlineTranslator.length > 0) {
            alert("Deepl 익스텐션을 사용하시면 에디터가 정상적으로 작동하지 않습니다. Deepl 익스텐션을 완전 삭제 하신 후 사용해주세요.");
            return true;
        }

        return false;
    }

    autoSave() {
        if (this.alreadySaving) {
            return;
        }

        if (this.isExceptionalEnvironment()) {
            return;
        }

        this.alreadySaving = true;
        this.editor.save().then((outputData) => {
            if (process.env.NODE_ENV === "development") {
                console.log(outputData.blocks)
            }
            if (this.data.get("post-type") === "new") {
                localStorage.setItem(this.data.get("post-ext-id"), JSON.stringify(outputData));
                this.markAutoSaveLabel();
            } else if (this.data.get("post-type") === "form") {
                this.editorDataTarget.value = JSON.stringify(outputData);
            } else {
                this.editorDataTarget.value = JSON.stringify(outputData);
                let params = {commit: "auto_save"}
                params[this.data.get("param-key")] = {
                    title: this.titleTarget.value,
                    subtitle: this.subtitleTarget.value,
                    tag_list: this.tagListTarget.value,
                    post_category_slug: this.postCategorySlugTarget.value,
                    editor_data: JSON.stringify(outputData)
                }
                api.patch(this.data.get("update-url"), params).then((res) => {
                    console.log(res)
                    this.markAutoSaveLabel();
                }).catch((error) => {
                    alert(error.response.data.error);
                });
            }

            if (this.data.get("post-type") !== "form") {
                this.checkAndSaveVersion(outputData);
            }

            this.alreadySaving = false;
        }).catch((e) => {
            console.log(e);
            this.alreadySaving = false;
        })
    }

    get repositoryKey() {
        return `versions-${this.data.get("post-ext-id")}`;
    }

    get currentRepository() {
        return JSON.parse(localStorage.getItem(this.repositoryKey)) || [];
    }

    get versionsController() {
        return this.application.getControllerForElementAndIdentifier(document.getElementById("noteVersions"), "notes--versions")
    }

    clearAndRenderDraft(editorData) {
        this.editor.blocks.clear();
        this.editor.blocks.render(editorData).then(() => {
            this.autoSave();
        });
    }

    clearAndRender(editorData) {
        this.editor.save().then((outputData) => {
            this.checkAndSaveVersion(outputData, true);
            this.editor.blocks.clear();
            this.editor.blocks.render(editorData).then(() => {
                this.autoSave();
            });
        });
    }

    checkAndSaveVersion(outputData, force = false) {
        let repository = this.currentRepository;

        try {
            const newData = outputData;
            const repositorySize = repository.length;
            const lastData = repository[repositorySize - 1];

            if (lastData) {
                const lastSavedAt = helpers.milisecondToHours(lastData.time)
                const newSavedAt = helpers.milisecondToHours(newData.time)
                const diff = newSavedAt - lastSavedAt
                const diffRule = (process.env.NODE_ENV === 'production' ? 0.25 : 0.001);
                if ((diff > diffRule) || force) {
                    if (repositorySize > 40) {
                        repository.shift();
                    }
                    repository.push(newData);
                    localStorage.setItem(this.repositoryKey, JSON.stringify(repository));
                    this.versionsController.reloadList();
                }
            } else {
                repository.push(newData);
                localStorage.setItem(this.repositoryKey, JSON.stringify(repository));
                this.versionsController.reloadList();
            }
        } catch (e) {
            const currentRepository = localStorage.getItem(this.repositoryKey)
            localStorage.clear();
            localStorage.setItem(this.repositoryKey, currentRepository)
        }
    }

    markAutoSaveLabel() {
        this.autoSavedLabelTargets.forEach((target) => {
            target.classList.remove("hidden");
            target.innerHTML = `${new Date().toLocaleTimeString()}<br>자동저장 됨`;
        });
    }

    // block = {id:, data: { items: [..], style: "unordered"  }, type:}
    legacyListToNestedList = (block) => {
        let blockData = block.data
        blockData.items = blockData.items.map((item) => {
            if (typeof(item) === "string") {
                return { content: item, items: [] };
            } else {
                return item;
            }
        })
        block.data = blockData;
        return block;
    }

    async connect() {
        let editorJsController = this;
        let defaultData = {};

        if (this.data.get("post-type") === "form") {
            defaultData = JSON.parse(`${this.data.get("default-data")}`);
        } else {
            let response = await api.get(this.data.get("fetch-url"))
            const {
                title,
                subtitle,
                editor_data,
                tag_list,
                post_category_slug
            } = response.data;

            this.titleTarget.value = title;
            this.subtitleTarget.value = subtitle;
            this.tagListTarget.value = tag_list;
            if (post_category_slug && post_category_slug.length > 0) {
                this.postCategorySlugTarget.value = post_category_slug;
            }
            defaultData = editor_data;
        }

        // block format 업데이트로 인한 data migration 코드들
        // block = {id:, data:, type:}
        defaultData.blocks = defaultData?.blocks?.map((block) => {
            switch (block.type) {
                case "list":
                    return this.legacyListToNestedList(block)
                default:
                    return block;
            }
        }) || []

        const directUploader = async (file) => {
            let resizedFile = file;

            if (file.size > 1000000) {
                const fileUrl = URL.createObjectURL(file)
                const image = await helpers.readImage(fileUrl);
                const resizedBlob = await readAndCompressImage(file, {
                    maxHeight: (file.type === "image/png" ? Math.max((image.height * 0.7), 800) : 1600),
                    maxWidth: (file.type === "image/png" ? Math.max((image.width * 0.7), 800) : 1600), // png 일 경우, 최소한 레터 너비 보다는 크게
                    quality: 0.7, mimeType: file.type
                });

                resizedFile = new File([resizedBlob], file.name);

                if (resizedFile.size > 3000000) {
                    alert("이미지 파일의 크기가 너무 커요. 최대 3MB 이하로 올려주세요.");
                    reject();
                }
            }

            const upload = new DirectUpload(resizedFile, `/rails/active_storage/direct_uploads?space_url=${editorJsController.data.get("space-url")}`, {
                directUploadWillStoreFileWithXHR: (xhr) => {
                    xhr.setRequestHeader('x-amz-acl', 'public-read'); // 디지털 오션에서만 필요. aws 는 storage.yml 설정으로 다 먹힘
                }
            });

            const uploadPromise = new Promise((resolve, reject) => {
                upload.create((error, blob) => {
                    if (error) {
                        reject(error);
                    } else {
                        resolve(blob)
                    }
                });
            });

            return uploadPromise.then((blob) => {
                return {
                    success: 1,
                    file: {
                        url: `${editorJsController.data.get("cdn-host")}/${blob.key}`,
                        // any other image data you want to store, such as width, height, color, extension, etc
                    }
                }
            });
        }

        this.editor = new EditorJS({
            onReady: () => {
                const undo = new Undo({editor: this.editor});
                undo.initialize(defaultData);
                new DragDrop(this.editor);
            },
            logLevel: process.env.NODE_ENV === 'production' ? 'ERROR' : 'ERROR',
            data: defaultData,
            holder: this.data.get("editor-container") || "editorContainer",
            placeholder: "내용을 입력해주세요.",
            i18n: korean,
            tools: {
                alignmentTune: {
                    class: AlignmentTuneTool,
                    config: {
                        default: "left",
                        blocks: {
                            header: 'left',
                            paragraph: 'left',
                            link: "left"
                        }
                    },
                },
                paragraph: {
                    class: Paragraph, // 데이터 마이그레이션 해주지 않을 거면 여기는 AlignmentTune 못씀
                    inlineToolbar: true,
                    config: {
                        preserveBlank: true,
                    }
                },
                header: {
                    class: Header,
                    tunes: ['alignmentTune'],
                    inlineToolbar: true,
                    shortcut: 'CMD+SHIFT+H',
                    config: {
                        levels: [2, 3, 4],
                        defaultLevel: 2,
                        placeholder: '헤더 내용을 입력하세요',
                        // blockTuneDisplayDefault: true,
                    },
                },
                ColorPicker: TextColor,
                linkTool: {
                    class: LinkTool,
                    tunes: ['alignmentTune'],
                    shortcut: 'CMD+L',
                    config: {
                        endpoint: '/api/links/parse',
                    }
                },
                image: {
                    class: ImageTool,
                    shortcut: 'CMD+SHIFT+I',
                    config: {
                        captionPlaceholder: "\t",

                        // blockTuneDisplayDefault: true,
                        uploader: {
                            async uploadByFile(file) {
                                return directUploader(file);
                            },
                            async uploadByUrl(url) {
                                const response = await fetch(url);
                                const blob = await response.blob();
                                const file = new File([blob], url.split("/").pop());
                                return directUploader(file);
                            }
                        }
                    }
                },
                list: {
                    class: NestedList,
                    shortcut: 'CMD+SHIFT+L',
                    inlineToolbar: true,
                    config: {
                        // blockTuneDisplayDefault: true,
                    }
                },
                quote: {
                    class: Quote,
                    inlineToolbar: true,
                    shortcut: 'CMD+SHIFT+O',
                    config: {
                        quotePlaceholder: '내용을 입력하세요',
                        captionPlaceholder: '\t'
                    },
                },
                code: {
                    class: CodeTool,
                    shortcut: 'CMD+SHIFT+B'
                },
                underline: {
                    class: Underline,
                    inlineToolbar: true,
                    shortcut: "CMD+U"
                },
                delimiter: {
                    class: Delimiter,
                    shortcut: 'CMD+SHIFT+D',
                },
                table: {
                    class: Table,
                    shortcut: 'CMD+SHIFT+E',
                    inlineToolbar: true,
                    config: {
                        rows: 2,
                        cols: 2,
                    },
                },
                Marker: {
                    class: Marker,
                    shortcut: 'CMD+M',
                },
                AnyButton: {
                    class: AnyButton,
                    inlineToolbar: false,
                    shortcut: 'CMD+SHIFT+A',
                    config: {
                        css: {
                            "btnColor": "editor-button",
                        },
                        defaultAlignment: "center",
                        defaultTextColor: "#000000",
                        defaultBackgroundColor: "#FFFFFF",
                    }
                },
                // raw: {
                //     class: RawTool,
                //     config: {
                //         placeholder: "필요하실 경우 HTML 코드로 작성하여 넣으실 수 있습니다."
                //     }
                // },
                embed: {
                    class: Embed,
                    config: {
                        services: {
                            youtube: true
                        }
                    }
                },
            },
        })

        let autoSavePeriod;

        if (this.data.get("post-type") === "form") {
            // TODO 뉴스레터 설정에서 메일러랑, 뉴스레터 하단부분 blob 생성
            // TODO 거기에서 editor_js 가 2개 붙어 있어서 양쪽 모두의 save 이벤트를 받아서 blob 을 input 에 집어넣고나서 form 제출이 되야하는데 일단 간단하게 처리
            autoSavePeriod = 1000;
        } else {
            autoSavePeriod = 10000;
        }

        this.autoSaver = setInterval(() => {
            if (document.hidden === true) return;
            this.autoSave();
        }, autoSavePeriod);

        // if (this.data.get("post-type") === "form") {
        //   this.autoSaver = setInterval(() => {
        //     if(document.hidden === true) return;
        //     this.autoSave();
        //   }, 1000);
        // }

        document.addEventListener("keydown", this.onClickCmdS);
    }

    onClickCmdS = (e) => {
        if ((window.navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey) && e.keyCode === 83) {
            e.preventDefault();
            this.autoSave();
        }
    };

    disconnect() {
        if (this.editor) {
            this.editor.isReady.then(() => {
                this.editor.destroy();
            });
        }

        if (this.autoSaver) {
            clearInterval(this.autoSaver);
        }

        document.removeEventListener("keydown", this.onClickCmdS);
    }
}