// <div data-controller="banner">
//
// </div>
import { Controller } from "stimulus";
import api from "@/utils/api";

export default class extends Controller {
  static targets = [ "" ];
  static values = {
    id: Number,
    redirectUrl: String,
  };

  increment() {
    api.post(`/ad_banners/${this.idValue}/click`).then((res) => {
      if (res) {
        window.location.href = this.redirectUrlValue;
      }
    })
  }

  connect() {
  }
}