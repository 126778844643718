// <div data-controller="designs">
//
// </div>
import { Controller } from "stimulus";
import api from "@/utils/api";

// https://stimulus.hotwired.dev/reference
export default class extends Controller {
  static targets = [
      "form"
  ];
  static classes = [ "" ]
  static values = {};

  initialize() {

  }

  onFilterChange(e) {
    this.formTarget.submit();
  }

  connect() {

  }

  disconnect() {

  }
}