// <div data-controller="components--adsense">
//
// </div>
import {Controller} from "stimulus";
import api from "@/utils/api";

export default class extends Controller {
    static targets = [];

    connect() {
        if (window.adsbygoogle) {
            (adsbygoogle = window.adsbygoogle || []).push({});
        }
    }
}