import {Controller} from "stimulus"
import api from "@/utils/api";
import {getCheckBoxIds, getCheckBoxLabel} from "@/utils/helpers";
import noonImage from "@assets/images/noon.jpg"
const LikeDefaultCss = "cursor-pointer focus:outline-none active:text-gray-400 transition duration-150 ease-in-out text-gray-200";
const LikeActiveCss = "cursor-pointer active:text-gray-200 transition duration-150 ease-in-out text-yellow-noon";

export default class extends Controller {
  static targets = [
    "filterForm", "remoteSubmit",
    "fontEditor", "fontEditorAll", "fontsContainer",
    "fontSizeLabel", "fontSizeAll",
    "fontWeightsCountLabel", "fontWeightsCountAll",
    "searchField", "categoryUseCheckBoxes", "categoryStyleCheckBoxes", "orderByOption",
    "categoryUseLabel", "categoryStyleLabel", "totalCount",
    "notFoundTemplate"
  ];

  static values = {
    page: Number,
    filterApi: String,
    loading: Boolean,
    finished: Boolean,
    params: Object,
    userSignedIn: Boolean,
    displayAdsense: Boolean,
    defaultFontSize: Number,
    fontPartial: String,
    cdnHost: String,
  };

  initialize() {

  }

  get flashesController() {
    return this.application.getControllerForElementAndIdentifier(document.getElementById("flashesContainer"), "components--flashes")
  }

  updateParams(key, value) {
    let params = this.paramsValue;
    if (value === "" || value.length === 0) {
      delete params[key];
    } else {
      params[key] = value;
    }
    this.paramsValue = params;
  }

  onResizeSliderScroll(e) {
    this.fontSizeLabelTarget.innerText = `${e.target.value}px`;
  }

  onResizeSliderChange(e) {
    this.resizeFonts();
  }

  onWeightsCountSliderScroll(e) {
    this.fontWeightsCountLabelTarget.innerText = `${e.target.value}개`;
  }

  onWeightsCountSliderChange(e) {
    const fontWeightsCount = this.hasFontWeightsCountAllTarget ? this.fontWeightsCountAllTarget.value : null;
    if (fontWeightsCount === null) {
      return;
    }

    this.updateParams("weights_count", fontWeightsCount);
    this.reloadFonts();
  }

  onTyping(e) {
    this.retypeFonts();
  }

  onFilterChange(e) {
    this.renderCheckBoxLabels();
    this.updateParams("category_use_ids", getCheckBoxIds(this.categoryUseCheckBoxesTargets));
    this.updateParams("category_style_ids", getCheckBoxIds(this.categoryStyleCheckBoxesTargets));
    this.updateParams("order_by", this.orderByOptionTarget.value);
    this.reloadFonts();
  }

  renderCheckBoxLabels() {
    this.categoryUseLabelTarget.innerText = getCheckBoxLabel(this.categoryUseCheckBoxesTargets, "허용 범위")
    this.categoryStyleLabelTarget.innerText = getCheckBoxLabel(this.categoryStyleCheckBoxesTargets, "폰트 형태");
  }

  onTypeSearchInput = (e) => {
    const keyCode = e.keyCode || e.which;
    if (keyCode === 13) {
      e.preventDefault();
      const trimKeyword = e.target.value.trim();
      this.updateParams("search", trimKeyword);
      this.reloadFonts();
      return;
    }
  }

  retypeFonts() {
    const fontEditorValue = this.hasFontEditorAllTarget ? this.fontEditorAllTarget.value : null;
    if (fontEditorValue === null) {
      return;
    }

    this.fontEditorTargets.forEach((target) => {
      target.value = fontEditorValue;
    });
  }

  resizeFonts() {
    const fontSize = this.hasFontSizeAllTarget ? this.fontSizeAllTarget.value : null;
    if (fontSize === null) {
      return;
    }

    this.updateParams("size", fontSize);
    this.updateUrlParams();

    if (this.fontPartialValue === "grid") {
      this.fontEditorTargets.forEach((target) => {
        target.style.fontSize = `${fontSize}px`;
        target.style.height = "";
        target.style.height = fontSize * 3 + "px"
      });
    } else {
      this.fontEditorTargets.forEach((target) => {
        if (target.tagName === "IMG") {
          target.style.height = "";
          target.style.height = `${parseFloat(fontSize) + 7}px`;
        } else {
          target.style.fontSize = `${fontSize}px`;
          target.style.height = `${parseFloat(fontSize) + 7}px`;
        }
      });
    }
  }

  listViewTemplate(font) {
    const {
      id, name, style, thumbnail_cdn_key, cdn_server_html
    } = font;
    const fontSizeAllValue = this.hasFontSizeAllTarget ? this.fontSizeAllTarget.value : null;

    if (thumbnail_cdn_key) {
      let fontImageHeight = "37px";
      if (fontSizeAllValue !== null) {
        fontImageHeight = `${parseFloat(fontSizeAllValue) + 7}px`
      }
      return `<a class="list-view-font lg:list-view-font" data-turbolinks="false" href="/font_page/${id}">
    <img class="font-image-preview" style="height: ${fontImageHeight};" alt="${name}" data-fonts-target="fontEditor" src="${this.cdnHostValue}/${thumbnail_cdn_key}" />
</a>`
    } else {
      let fontSpanStyle = style;
      if (fontSizeAllValue !== null) {
        fontSpanStyle = `${style};font-size: ${fontSizeAllValue}px;`
      }

      return `<a class="list-view-font lg:list-view-font" data-turbolinks="false" href="/font_page/${id}">
    <style>${cdn_server_html}</style>
    <span style="${fontSpanStyle};" data-fonts-target="fontEditor" id="fontEditor${id}">${name}</span>
</a>`
    }
  }

  gridViewTemplate(font) {
    const {
      id, name, style, ph_content,
      loaded_current_user_font,
      company, thumbnail_cdn_key, font_family_token, cdn_server_html, families_count
    } = font;
    const fontSizeAllValue = this.hasFontSizeAllTarget ? this.fontSizeAllTarget.value : null;

    let fontCardHeader = `<span class="text-2xl group-hover-noon-background" style="${style}">${name}</span>`;
    if (thumbnail_cdn_key) {
      fontCardHeader = `<div class="h-8"><img class="h-full object-contain group-hover-noon-background font-image-preview" loading="lazy" alt="${name}" src="${this.cdnHostValue}/${thumbnail_cdn_key}" /></div>`
    }

    let textAreaStyle = style;
    if (fontSizeAllValue !== null) {
      textAreaStyle = `${style};font-size: ${fontSizeAllValue}px; height: ${fontSizeAllValue * 3}px;`
    }

    let familiesCountArea = `<div></div>`;
    if (families_count > 1) {
      familiesCountArea = `<p class="text-sm text-gray-900 dark:text-gray-100 truncate" style="${style}">${families_count}가지 굵기</p>`
    }

    const fontTextArea = `<textarea style="${textAreaStyle};" placeholder="${ph_content}" id="fontEditor${id}" class="text-gray-900 dark:text-gray-100 bg-white dark:bg-zinc-800 text-3xl leading-snug resize-none h-36 min-h-36 w-full appearance-none placeholder-current p-0 outline-none border-none focus:outline-none focus:ring-0 focus:border-none" spellcheck="false" lang="ko" name="editor" data-fonts-target="fontEditor">${this.fontEditorAllTarget.value}</textarea>`

    return `<div class="text-gray-900 dark:text-gray-100 bg-white dark:bg-zinc-800 border border-zinc-200 dark:border-zinc-600 rounded-sm group flex flex-col justify-between hover:shadow-md duration-300">
    <style>${cdn_server_html}</style>
  <a class="pt-4 px-5" data-turbolinks="false" href="/font_page/${id}">
    <div class="flex">
      <div class="flex-1">${fontCardHeader}</div>
      <div class="text-white dark:text-zinc-800 group-hover:text-gray-900 dark:group-hover:text-gray-100">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 stroke-current" width="44" height="44" viewBox="0 0 12 24" stroke-width="1" stroke="#2c3e50" fill="none" stroke-linecap="round" stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <polyline points="9 6 15 12 9 18"></polyline>
        </svg>
      </div>
    </div>
    <p class="text-sm font-bold truncate" style="${style}">${company.name}</p>
  </a>
  <div class="border-t border-zinc-200 dark:border-zinc-600 my-4 group-hover:mx-0 group-hover:border-yellow-noon group-hover:border-t"></div>
  <div class="font-loading px-5 flex-1 overflow-hidden" data-font-family-token="${font_family_token}">
    ${fontTextArea}
  </div>
  <div class="px-5 pb-4 flex justify-between">
    ${familiesCountArea}
    <div data-action="click->fonts#toggleFont"
         data-font-id="${id}"
         class="${loaded_current_user_font ? LikeActiveCss : LikeDefaultCss}"
         id="fontStar${id}">
      <svg xmlns="http://www.w3.org/2000/svg"
           class="h-6 w-6 stroke-current fill-current pointer-events-none"
           width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round" stroke-linejoin="round">
        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
        <path d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z"/>
      </svg>
    </div>
  </div>
</div>`
  }

  squareAdTemplate() {
    let adBox;
    if (process.env.NODE_ENV === 'production') {
      adBox = `<div class="group min-h-auto" data-controller="components--adsense">
        <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1314414100334102"
             crossorigin="anonymous"></script>
        <!-- square-ad-every-fonts -->
        <ins class="adsbygoogle"
             style="display:block"
             data-ad-client="ca-pub-1314414100334102"
             data-ad-slot="9360101619"
             data-ad-format="auto"
             data-full-width-responsive="true"></ins>
      </div>`;
    } else {
      adBox = `<div data-controller="components--adsense" class="bg-white dark:bg-zinc-800 border border-gray-200 dark:border-zinc-600 rounded-sm group flex items-center justify-center min-h-auto">
    <div class="flex flex-col items-center justify-center" style="height: 280px; width: 100%;">
      <img src="${noonImage}" class="h-16 w-auto"/>
      <p class="text-gray-500 dark:text-gray-300 mt-2 text-sm">눈누 AD</p>
    </div>
  </div>`
    }

    return adBox;
  }

  fireFontLoader(tokens) {
    if (WebFont) {
      WebFont.load({
        google: {
          families: tokens,
        },
        timeout: process.env.NODE_ENV === 'production' ? 15000 : 2000000,
        classes: false,
        fontinactive: function(familyName, fvd) {
          const fontCards = document.querySelectorAll(`[data-font-family-token="${familyName}"]`);
          fontCards.forEach((fontCard) => {
            fontCard.classList.remove("font-loading");
          })
        },
        fontactive: function(familyName, fvd) {
          const fontCards = document.querySelectorAll(`[data-font-family-token="${familyName}"]`);
          fontCards.forEach((fontCard) => {
            fontCard.classList.remove("font-loading");
          })
        },
      });
    }
  }

  visitListView(e) {
    this.updateParams("view", "list");
    const searchParams = new URLSearchParams(this.paramsValue);
    const newUrl = window.location.pathname + "?" + searchParams.toString()
    window.location.href = newUrl;
  }

  visitGridView(e) {
    this.updateParams("view", "");
    const searchParams = new URLSearchParams(this.paramsValue);
    const newUrl = window.location.pathname + "?" + searchParams.toString()
    window.location.href = newUrl;
  }

  updateUrlParams() {
    const searchParams = new URLSearchParams(this.paramsValue);
    const newUrl = window.location.pathname + "?" + searchParams.toString()
    history.replaceState(this.paramsValue, '', newUrl);
  }

  reloadFonts() {
    const currentScrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    this.updateUrlParams();
    this.pageValue = 0; // 여기는 클라이언트에서 처음부터 다시그리기 때문에 page 가 0부터 시작한다.

    if (currentScrollTop > 400) {
      window.scrollTo(0, 400)
    }
    this.loadFonts();
  }

  renderNotFound() {
    this.fontsContainerTarget.innerHTML = this.notFoundTemplateTarget.innerHTML;
  }

  loadFonts() {
    this.loadingValue = true;
    const currentPage = this.hasPageValue ? this.pageValue : 1;
    const nextPage = currentPage + 1;

    api.get(`${this.filterApiValue}.json`, {
      page: nextPage, ...this.paramsValue
    }).then((res) => {
      const {fonts, is_last_page, total_count} = res.data;
      if (nextPage === 1 && total_count === 0) {
        this.renderNotFound();
        return;
      }
      if (this.hasTotalCountTarget) {
        this.totalCountTarget.innerText = `${total_count}개`
      }
      this.renderFonts(fonts);
      this.pageValue = nextPage;
      this.loadingValue = false;
      this.finishedValue = is_last_page;
    })
  }

  renderFonts(fonts) {
    console.log(fonts)
    let cardListHTML = "";
    let tokens = []
    fonts.forEach((font) => {
      cardListHTML += this.fontCard(font);
      tokens.push(font.font_family_token)
    });

    if (this.fontPartialValue === "grid" && tokens.length > 0) {
      this.fireFontLoader(tokens)
    }
    if (this.pageValue === 0) {
      if (this.fontPartialValue === "grid" && this.displayAdsenseValue && fonts.length > 0) {
        cardListHTML = this.squareAdTemplate() + cardListHTML;
      }
      this.fontsContainerTarget.innerHTML = cardListHTML;
    } else {
      this.fontsContainerTarget.insertAdjacentHTML('beforeend', cardListHTML)
    }
  }

  fontCard(font) {
    let fontTemplate;
    if (this.fontPartialValue === "grid") {
      fontTemplate = this.gridViewTemplate(font);
    } else {
      fontTemplate = this.listViewTemplate(font);
    }
    return fontTemplate;
  }

  onScroll() {
    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight - 1500) {
      if (!this.loadingValue && !this.finishedValue) {
        this.loadFonts();
      }
    }
  }

  toggleFont = (e) => {
    const { fontId } = e.target.dataset;

    if (this.userSignedInValue) {
      api.post(`/api/fonts/${fontId}/toggle`)
          .then((res) => {
            if (res.data) {
              const { checked, fontName  } = res.data;
              const linkToUserFonts = `<a href='/user_fonts' class='font-bold hover:underline'>즐겨찾기</a>`
              let flashParams = { stack: false, ttl: 7 };
              if (checked) {
                flashParams.message = `${fontName} 폰트가 ${linkToUserFonts}에 추가 되었습니다.`;
              } else {
                flashParams.message = `${fontName} 폰트가 ${linkToUserFonts}에서 제거 되었습니다.`;
              }
              this.flashesController.createFlash(flashParams);
              e.target.className = (checked ? LikeActiveCss : LikeDefaultCss);
            }
          })
    } else {
      let flashParams = { ttl: 15, stack: false };
      flashParams.message = `즐겨찾기 기능은 로그인이 필요해요.<br><a href='/users/sign_in' class='block mt-1 font-bold hover:underline'>바로 로그인하기 &rarr;</a>`;
      this.flashesController.createFlash(flashParams);
    }
  }

  preventEnterSubmit = (e) => {
    const code = e.keyCode || e.which;
    if (code === 13) {
      e.preventDefault();
      return false;
    }
  }

  connect() {
    if (!this.loadingValue && !this.finishedValue) {
      this.renderCheckBoxLabels();
      this.loadFonts();
    }

    if (this.hasFontEditorAllTarget) {
      this.fontEditorAllTarget.addEventListener("keypress", this.preventEnterSubmit)
    }
  }

  disconnect() {
    if (this.hasFontEditorAllTarget) {
      this.fontEditorAllTarget.removeEventListener("keypress", this.preventEnterSubmit)
    }
  }
}
