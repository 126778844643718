// <div data-controller="components--darkmode">
//
// </div>
import { Controller } from "stimulus";
import api from "@/utils/api";
import nightwind from "nightwind/helper"

// https://stimulus.hotwired.dev/reference
export default class extends Controller {
  static targets = [ "toggleButton" ];
  static classes = [ "" ]
  static values = {};

  initialize() {

  }

  toggleColorMode() {
    nightwind.toggle();
  }

  connect() {
  }

  disconnect() {

  }
}