// <div data-controller="fonts--show">
//
// </div>
import { Controller } from "stimulus";
import api from "@/utils/api";

// https://stimulus.hotwired.dev/reference
export default class extends Controller {
  static targets = [ "fontEditorAll", "fontEditor", "fontSizeAll", "fontSizeLabel" ];
  static classes = [ "" ]
  static values = {};

  initialize() {

  }

  onTyping(e) {
    this.retypeFonts();
  }

  retypeFonts() {
    const fontEditorValue = this.hasFontEditorAllTarget ? this.fontEditorAllTarget.value : null;

    this.fontEditorTargets.forEach((target) => {
      if (fontEditorValue === null || fontEditorValue === "") {
        target.value = target.dataset.defaultText;
      } else {
        target.value = fontEditorValue;
      }
    });
  }

  onResizeSliderScroll(e) {
    this.resizeFonts();
    this.fontSizeLabelTarget.innerText = `${e.target.value}px`;
  }

  onResizeSliderChange(e) {
    this.resizeFonts();
  }

  resizeFonts() {
    const fontSize = this.hasFontSizeAllTarget ? this.fontSizeAllTarget.value : null;
    if (fontSize === null) {
      return;
    }

    this.fontEditorTargets.forEach((target) => {
      target.style.fontSize = `${fontSize}px`;
      target.style.lineHeight = `${parseFloat(fontSize) * 4/3}px`;
    });
  }

  connect() {
  }

  disconnect() {

  }
}