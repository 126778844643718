// <div data-controller="designs--show">
//
// </div>
import { Controller } from "stimulus";
import api from "@/utils/api";

// https://stimulus.hotwired.dev/reference
export default class extends Controller {
  static targets = [ "" ];
  static classes = [ "" ]
  static values = {
    id: Number
  };

  initialize() {

  }

  view(e) {
    api.post(`/api/designs/${this.idValue}/view`).then((res) => {
      // nothing yet
    })
  }

  connect() {
  }

  disconnect() {

  }
}