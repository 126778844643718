// <div data-controller="font">
//
// </div>
import { Controller } from "stimulus";
import api from "@/utils/api";

// https://stimulus.hotwired.dev/reference
export default class extends Controller {
  static targets = [ "" ];
  static classes = [ "" ]
  static values = {
    userSignedIn: Boolean,
  };

  initialize() {

  }

  get flashesController() {
    return this.application.getControllerForElementAndIdentifier(document.getElementById("flashesContainer"), "components--flashes")
  }

  // 폰트 개별 페이지 즐찾
  toggleFont = (e) => {
    const { fontId, activeCss, defaultCss } = e.target.dataset;

    if (this.userSignedInValue) {
      api.post(`/api/fonts/${fontId}/toggle`)
        .then((res) => {
          if (res.data) {
            const { checked, fontName  } = res.data;
            const linkToUserFonts = `<a href='/user_fonts' class='font-bold hover:underline'>즐겨찾기</a>`
            let flashParams = { stack: false, ttl: 7 };
            if (checked) {
              flashParams.message = `${fontName} 폰트가 ${linkToUserFonts}에 추가 되었습니다.`;
            } else {
              flashParams.message = `${fontName} 폰트가 ${linkToUserFonts}에서 제거 되었습니다.`;
            }
            this.flashesController.createFlash(flashParams);
            e.target.className = (checked ? activeCss : defaultCss);
          }
        })
    } else {
      let flashParams = { ttl: 15, stack: false };
      flashParams.message = `즐겨찾기 기능은 로그인이 필요해요.<br><a href='/users/sign_in' class='block mt-1 font-bold hover:underline'>바로 로그인하기 &rarr;</a>`;
      this.flashesController.createFlash(flashParams);
    }
  }

  connect() {

  }

  disconnect() {

  }
}