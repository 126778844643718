// <div data-controller="components--flashes">
//
// </div>
import { Controller } from "stimulus";
import api from "@/utils/api";

// https://stimulus.hotwired.dev/reference
export default class extends Controller {
  static targets = [ "container" ];
  static classes = [ "" ]
  static values = {};

  createFlash = ({ key = null, message, ttl = 5, stack = true }) => {
    const template = `<div data-controller="components--flash"
           data-components--flash-key-value="${key}"
           data-components--flash-ttl-value="${ttl}"
           data-components--flash-message-value="${message}"
           data-components--flash-target="container"></div>`;
    if (stack === false) {
      this.containerTarget.innerHTML = null;
    }

    this.containerTarget.insertAdjacentHTML('beforeend', template)
  }
}
